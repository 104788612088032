@import "_defaults";
@import "bootstrap/bootstrap";
@import "header";
@import "footer";
@import "fooldal";
@import "info-sav";
@import "hirek";
@import "elemzesek";
@import "cimke";
@import "media";
@import "szoveg";
@import "szolgaltatasok";
@import "rendezvenyek";
@import "kutatas";
@import "munkatars";
@import "fancybox";

@media (max-width: @screen-sm-max) {
	@import "mobil/header";
	@import "mobil/fooldal";
	@import "mobil/hirek";
	@import "mobil/szolgaltatasok";

	.thumbnail {
		> .media-object {
			width: 100%;
		}
	}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	@import "tablet/header";
	@import "tablet/fooldal";
	@import "tablet/hirek";
	@import "tablet/szolgaltatasok";
	@import "tablet/info-sav";


	.thumbnail {
		> .media-object {
			width: 100%;
		}
	}
}

.btn-primary {
	border-radius: 0;
	font-weight: bold;
	font-size: 20px;
}

.oldal-cimlec {
	background-image: url("@{images-url}media-hatter.jpg");
	background-position: bottom;
	margin-bottom: 44px;
	height: 100px;

	h2 {
		margin-top: 35px;
		color: #fff;
		font-size: 25px;
		font-weight: bold;

		> span {
			border-bottom: 3px solid #fff;
			text-transform: uppercase;
		}
	}
}

.hireink-oldalso {
	@media (max-width: @screen-sm-max) {
		margin-top: 5vw;
	}
	h3 {
		font-size: 20px;
		font-weight: bold;
		margin-top: 0;
		margin-bottom: 52px;
		> span {
			border-bottom: 3px solid @bordo;
		}
	}
	> div {
		margin-bottom: 10px;
		@media (max-width: @screen-sm-max) {
			margin-bottom: 2vw;
		}
		.media {
			text-decoration: none;
			margin-bottom: 25px;
			> .media-body {
				color: #999;
				font-size: 14px;
				@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
					font-size: 13px;
				}
				> .media-heading {
					font-size: 16px;
					font-weight: bold;
					color: #737373;
				}
			}
			&:hover {
				.media-heading {
					color: @bordo;
				}
			}
		}
	}
}

.lapozo {
	> .pagination {
		> li {
			> span, a {
				color: #ccc;
				border: none;
				font-size: 20px;
				//font-weight: bold;
			}
		}
		> .active {
			> span, a {
				background-color: transparent;
				color: @bordo;
			}
		}
	}
}

blockquote {
	margin-left: 20px;
	border-left: 6px solid @bordo;
	line-height: 1.2;
	font-style: italic;
	font-size: 18px;
	color: #666666;
}

.kereses-mezo {
	.mezo {
		visibility: hidden;
		width: 0;
		position: absolute;
		top: 0;
		right: 0;

		.form-control{
			height: 32px;
			border-radius: 0;
		}
	}
}

.modal {
	.modal-dialog {
		.modal-content {
			.modal-header {
				.modal-title {
					color: @bordo;
				}
			}
		}
	}
}

.ertesito {
	position: fixed;
	top: 19%;
	left: 0;
	right: 0;
	z-index: 10000;
}

.cc_banner-wrapper {
	.cc_container {

		.cc_message {
			color: @bordo;
			font-weight: bold;
		}
		> .cc_btn {
			background: @szurke !important;
			color: @bordo !important;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 1px;
			&:hover{
				background: @sotetszurke !important;
			}
		}

		.cc_more_info{
			color: @bordo;
			&:hover{
				color: white;
			}
		}
	}
}