#hirek {
	.hirek-lista {
		> .media {
			margin-bottom: 50px;
			.media-body {
				> .media-heading {
					color: #999;
					h3 {
						margin-top: 10px;
						font-size: 17px;
					}
					hr {
						margin: 5px 0;
					}
				}
				> .lead {
					font-size: 14px;
					margin-bottom: 15px;
				}
				.hirek-gomb {
					font-weight: bold;
				}
			}
		}
	}
}