header {
	height
	> .container {
		> .felso-resz {
			min-height: 125px;
			> .brand-image {
				margin-bottom: 45px;
			}
		}
		> .navbar-default {
			> .container-fluid {
				padding-left: 0;
				> .navbar-collapse {
					padding-left: 20px;
					> .navbar-nav {
						margin-left: 0;
						margin-right: 0;
						> li {
							> a {
								> span {
									padding: 0 15px;
									border: none;
								}
							}
						}
						> li:first-child {
							> a > span {
								padding-left: 15px;
							}
						}
						/*> .active {
							> a {
								background-color: #fff;
								border-top: 3px solid @bordo;
								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 50%;
									margin-left: -6px;
									width: 12px;
									height: 12px;
									border: 6px solid transparent;
									border-top: 6px solid @bordo;
								}
							}
						}*/
					}
					.navbar-right{
						float: right;
					}
				}
			}
		}
	}
}
