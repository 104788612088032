#fooldal {

	> #fooldal-slider {
		> .carousel-inner {
			> .item {
				> img {
						max-width: none;
						height: 100%;
				}
				> .carousel-caption {
					top: 10%;
					> .tartalom {
						width: 100%;
						> h3 {
							font-size: 20px;
						}
						> p {
							font-size: 14px;
						}

						> .btn {
							padding: 10px 21px 10px;
							font-size: 15px;
						}
					}
				}
			}
		}

		> .carousel-control {
			> .fooldal-lapozo {
				position: absolute;
				top: 50%;
				display: inline-block;
			}
			&.left{
				//right: 10%;
				background-image: none;
			}
			&.right{
				right: 10%;
				background-image: none;
			}
		}
	}

}