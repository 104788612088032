#info{
	>.container {
		>.row {
			h2 {
				>span{
					font-size: 22px;
				}
			}
			p{
				font-size: 17px;
			}
			.info-form{
				font-size: 17px;

			}
			.tovabb{
				margin-top: 30px;
			}
			form{
				>.row{
					margin-left: 0;
					#hirlevel-kod{
						margin-right: 38px;
					}

					input{
						border: 5px solid #555;
						background-color: #555;
						color: #fff;
					}


				}
				>.feliratkozas{
					padding: 7px 15px;
					background: @bordo;
					color: #fff;
					text-decoration: none;
					font-size: 17px;
				}
			}
		}
	}
}