#cimke{
	.hir {
		.media {
			margin-bottom: 40px;
			> .media-left {
				> .thumbnail {
					box-shadow: 0 0 10px #999;
					img {
						max-width: none;
					}
				}
			}
			.media-body {
				> .media-heading {
					color: #999;
					.datum {
						margin-bottom: 5px;
					}
					h3 {
						margin-top: 10px;
						font-size: 20px;
						font-weight: bold;
						color: #444;
					}
					hr {
						margin: 5px 0;
					}
					a {
						text-decoration: none;
						color: #444;
						&:hover {
							color: @bordo;
						}
					}
				}
				> .lead {
					font-size: 16px;
					margin-bottom: 15px;
					color: #444;
					line-height: 1.2;
				}
				.hirek-gomb {
					font-weight: bold;
				}
			}
		}
	}
	.elemzes {
		margin-bottom: 2.5em;

		.cimkek {
			border-bottom: 1px solid @gray-light;

			a {
				color: @gray-light;
			}
		}

		.cim {
			font-weight: bold;
			font-size: 20px;
			margin-top: .25em;
			position: relative;

			&:hover {
				color: @bordo;
			}
		}
	}
}