#info{
	background-color: #444;
	background-image: url("@{images-url}/info-map.png");
	background-size: cover;
	height: auto;
	color: #fff;
	>.container {
		>.row {
			>div{
				margin-bottom: 20px;
			}
			@media (min-width: @screen-md-min) and (max-width: @screen-md-max){
				font-size: 13px;
			}
			h2 {
				>span{
					border-bottom: 3px solid #fff;
					font-size: 18px;
					font-weight: 600;
				}
			}
			.info-form{
				margin-top: 23px;
				margin-bottom: 40px;

			}
			.magunkrol-szoveg{
				margin-bottom: 25px;
			}
			.tovabb{
				margin-top: 20px;
				padding: 7px 10px;
				background: @bordo;
				color: #fff;
				text-decoration: none;
				font-weight: 600;
				font-size: 17px;
				&:hover{
					background-color: #6a1d1b;
				}

			}
			form{
				>.row{
					margin-left: 0;
					#hirlevel-kod{
						margin-right: 37px;
						@media (min-width: @screen-md-min) and (max-width: @screen-md-max){
							margin-right: 8px;
						}
					}

					input{
						border: 5px solid #555;
						background-color: #555;
						color: #fff;
					}


				}
				>.feliratkozas{
					padding: 7px 15px;
					background: @bordo;
					color: #fff;
					text-decoration: none;
					font-weight: 600;
					font-size: 17px;
					&:hover{
						background-color: #6a1d1b;
					}
				}
			}
		}
	}
}