footer {
	background-color: #333;
	color: #8f8f8f;
	a {
		color: #8f8f8f;
		text-decoration: none;
		&:hover {
			text-decoration: none;
			color: white;
		}
	}
	> .container {
		> .row {
			padding-top: 15px;
			padding-bottom: 10px;
			.visible-md.elvalaszto, .visible-lg.elvalaszto {
				display: inline !important;
			}
			.brusszel {
				@media (min-width: @screen-md-min) {
					padding-left: 37px;
				}
			}
			.en{
				.brusszel {
					@media (min-width: @screen-md-min) {
						padding-left: 41px;
					}
				}
			}

			> .social-icons {
				padding-top: 8px;
				img {
					vertical-align: bottom;
				}
			}
		}
	}
}