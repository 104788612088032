// TODO: app.less elején importáld

@root-url:				"../../";
@fonts-url:				"@{root-url}fonts/";
@images-url:			"@{root-url}images/";
@datauri-images-url:    "../../../public/images/"; // data-urihoz lesshez képesti relatív útvonal kell

.vertical-align {
	font-size: 0;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle
	}

	.vertical-align-middle {
		vertical-align: middle;
		display: inline-block;
		max-width: 100%
	}
}
