#szoveg {
	.szoveg {
		h1, h2, h3{
			color: @bordo;
			font-size: 30px;
			margin-top: 55px;
			&:first-child{
				margin-top: 0;
			}
		}

		h4, h5, h6 {
			color: @bordo;
			font-size: 25px;
			margin-top: 55px;
			&:first-child{
				margin-top: 0;
			}
		}

		p{
			margin-bottom: 30px;
		}
		font-size: 15px;
		color: #444;
		margin-bottom: 30px;
	}
}