#szolgaltatsok {
	padding-bottom: 55px;
	.szolgaltatas-lista {
		line-height: 1.2;
		.media {
			@media (min-width: @screen-md-min) {
				margin-top: 60px;
			}
			.media-left{
				margin-right: 20px;
				.thumbnail{
					box-shadow: 0 0 15px #555;
					border-radius: 0;
				}
				img{
					max-width: none;
				}
			}
			.media-body {
				padding-left: 20px;
				.cim {
					margin-top: 0;
					margin-bottom: 15px;
					font-size: 20px;
					font-weight: bold;
				}
				.lead,.szoveg {
					font-size: 16px;
					line-height: 1.2;
					color: #444;
				}
			}
		}
	}
}

#szolgaltatas {
	.lead{

	}

	.szoveg{
		h1, h2, h3{
			color: @bordo;
			font-size: 30px;
			margin-top: 55px;
			&:first-child{
				margin-top: 0;
			}
		}

		h4, h5, h6 {
			color: @bordo;
			font-size: 25px;
			margin-top: 55px;
			&:first-child{
				margin-top: 0;
			}
		}

		p{
			margin-bottom: 30px;
		}
		font-size: 16px;
		color: #444;
		margin-bottom: 30px;
	}
}