#kutatasi-temak {
	.temak-lista {
		> .tema {
			margin-bottom: 50px;
			a {
				text-decoration: none;
			}
			.thumbnail {
				width: 150px;
				height: 150px;
				margin-left: auto;
				margin-right: auto;
				position: relative;
				.ikon-hatter {
					position: absolute;
					top: 4px;
					left: 4px;
					bottom: 4px;
					right: 4px;
					background-color: #e9e9e9;
					svg {
						max-width: 140px;
						max-height: 140px;
						padding: 21px;
						.vertical-align > .vertical-align-middle;
						.st3, .st4 {
							fill: #706f6f;
						}
					}
				}

			}
			.cim {
				color: @bordo;
				font-size: 20px;
				height: 56px;
			}
			.lead {
				font-size: 14px;
				color: #444;
			}
			.btn-primary {
				font-size: 16px;
			}
			&:hover {
				.thumbnail {

					.ikon-hatter {

						background-color: @bordo;
						svg {
							.st3, .st4 {
								fill: #fff;
							}
							.st2 {
								fill: @bordo;
							}
						}
					}
				}
			}
		}
	}
}

#kutatasi-tema {
	.lead {

	}

	.szoveg {
		h1, h2, h3 {
			color: @bordo;
			font-size: 30px;
			margin-top: 55px;
			&:first-child {
				margin-top: 0;
			}
		}

		h4, h5, h6 {
			color: @bordo;
			font-size: 25px;
			margin-top: 55px;
			&:first-child {
				margin-top: 0;
			}
		}

		p {
			margin-bottom: 30px;
		}
		font-size: 16px;
		color: #444;
		margin-bottom: 30px;
	}
}