#rendezvenyek {
	padding-bottom: 55px;
	.rendezveny-lista {
		> a {
			text-decoration: none;
			.media {
				.media-left {
					margin-right: 20px;
					div {
						box-shadow: 0 0 15px #555;
						border-radius: 0;
					}
					img {
						max-width: none;
					}
				}
				.media-right {
					div {
						width: 172px;
						p {
							margin-bottom: 0;
							color: #b3b3b3;
							font-size: 16px;
							line-height: 1;
						}
						hr {
							margin-top: 4px;
							margin-bottom: 12px;

						}
						.informacio {
							color: @bordo;
							font-size: 16px;
							font-weight: bold;
							margin-bottom: 34px;
							line-height: 1;
						}
					}
				}
				.media-body {
					padding-left: 20px;
					.cim {
						margin-top: 0;
						margin-bottom: 15px;
						font-size: 20px;
						font-weight: bold;
						color: #444;
					}
					.lead {
						font-size: 14px;
						line-height: 1.2;
						color: #444;
					}
				}
			}
			.infok-mobilon{
				margin-bottom: 60px;
				p {
					margin-bottom: 0;
					color: #b3b3b3;
					font-size: 16px;
					line-height: 1;
				}
				hr {
					margin-top: 4px;
					margin-bottom: 12px;

				}
				.informacio {
					color: @bordo;
					font-size: 16px;
					font-weight: bold;
					margin-bottom: 34px;
					line-height: 1;
				}
			}

			&:hover{
				.media-body {
					.cim {
						color: @bordo;
					}
				}
			}
		}
		.elvalaszto{
			border-color: #555;
		}
	}
}

#rendezveny {
	.lead{

	}

	.informacio-sav{
		font-size: 16px;
		.informacio{
			color: @bordo;
			font-weight: bold;
			margin-left: 10px;
		}
	}

	.szoveg{
		h1, h2, h3{
			color: @bordo;
			font-size: 30px;
			margin-top: 55px;
			&:first-child{
				margin-top: 0;
			}
		}

		h4, h5, h6 {
			color: @bordo;
			font-size: 25px;
			margin-top: 55px;
			&:first-child{
				margin-top: 0;
			}
		}

		p{
			margin-bottom: 30px;
		}
		font-size: 16px;
		color: #444;
		margin-bottom: 30px;
	}

	.kepek{
		a{
			&:hover{
				>.thumbnail{
					background-color: @bordo;
				}
			}
		}
	}
}