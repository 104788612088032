#media-lista {
	.media-elem {
		position: relative;
		margin-bottom: 50px;
		> a {
			text-decoration: none;
			> .kep {
				border-radius: 0;
				box-shadow: 0 0 10px #555;
				overflow: hidden;
				position: relative;

				>.youtube-div{
					background-position: center;
					background-size: cover;
					position: absolute;
					top: 4px;
					left: 4px;
					bottom: 4px;
					right: 4px;
				}
				 .media-kep {
					//border-radius: 0;
					//box-shadow: 0 0 10px #555;
				}
				> .lejatszo {
					position: absolute;
					top: 0;
					left: 10px;
					cursor: pointer;
				}

				>svg{
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					margin: auto;
					max-width: 100px;

					circle, path ,g > circle, g > path{
						fill: #fff;
					}

					polygon, .st0{
						fill: @bordo;
					}
				}
			}
			.datum {
				margin-top: 15px;
				margin-bottom: 5px;
				border-bottom: 1px solid #999;
				color: #999;
			}
			.cim {
				font-weight: bold;
				font-size: 20px;
				color: #000;
			}
			&:hover {
				.cim {
					color: @bordo;
				}
			}
		}
	}
}