#fooldal {

	> #fooldal-slider {
		> .carousel-inner {
			> .item {
				> img {
				}
				> .carousel-caption {
					top: 20%;
					left: 0;
					right: 0;
					@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
						top: 10%;
					}
					> .tartalom {
						float: left;
						text-shadow: none;
						> h3 {
							color: @bordo;
							font-size: 30px;
							@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
								font-size: 27px;
							}
							> span {
								border-left: 10px solid rgba(255,255,255,0.1);
								background: rgba(255,255,255,0.85);
								box-shadow: 15px 0 0 rgba(255,255,255,0.85);
								line-height: 150%;
							}
						}
						> p {
							font-size: 20px;
							overflow: hidden;
							@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
								font-size: 17px;
							}
							color: #000;
							border-left: 10px solid rgba(255,255,255,0.85);
							> span {
								background: rgba(255,255,255,0.85);
								box-shadow: 15px 0 0 rgba(255,255,255,0.85);
								line-height: 156%;
							}
						}

						> .btn {
							padding: 12px 24px 12px;
							font-size: 20px;
						}
					}
				}
			}
		}

		> .carousel-control {
			> .fooldal-lapozo {
				position: absolute;
				top: 50%;
				display: inline-block;
			}
		}
	}

	> .container {

		> .tovabbi-hireink, > .kiemelt-kutatasi-temak, > .kiemelt-projektek {
			> h2 {
				font-size: 20px;
				font-weight: bold;
				> span {
					border-bottom: 3px solid @bordo;
				}
			}
		}

		> .tovabbi-hireink {
			> h2 {
				margin-bottom: 20px;
			}
			> .row {
				margin-left: -30px;
				.thumbnail {
					border: 0;
					> .caption {
						a {

							img {
								border: 4px solid #fff;
								box-shadow: 0 0 15px #444;
								margin-bottom: 10px;
							}
							.datum {
								margin-bottom: 5px;
								border-bottom: 1px solid #999;
								color: #999;
							}

							h3 {
								font-size: 16px;
								font-weight: bold;
								margin-top: 0;
								margin-bottom: 18px;
								color: #000;
								height: 51px;
							}
							.hir-szoveg {
								font-size: 14px;
								color: #444;
								height: 130px;
							}
							&:hover {
								text-decoration: none;
								h3 {
									color: @bordo;

								}
							}
						}
					}
				}
			}
		}

		> .kiemelt-kutatasi-temak {
			> h2 {
				margin-bottom: 40px;
			}

			img {
				padding: 5px;
				border: 1px solid #e9e9e9;
			}
			.thumbnail {
				width: 150px;
				height: 150px;
				margin-left: auto;
				margin-right: auto;
				position: relative;
				.ikon-hatter {
					position: absolute;
					top: 4px;
					left: 4px;
					bottom: 4px;
					right: 4px;
					background-color: #e9e9e9;
					svg {
						max-width: 140px;
						max-height: 140px;
						padding: 21px;
						.vertical-align > .vertical-align-middle;
						.st3, .st4 {
							fill: #706f6f;
						}
					}
				}
			}
			.tema {
				&:hover {
					.thumbnail {

						.ikon-hatter {

							background-color: @bordo;
							svg {
								.st3, .st4 {
									fill: #fff;
								}
								.st2 {
									fill: @bordo;
								}
							}
						}
					}
				}
			}

			h3 {
				font-size: 20px;
			}

			a {
				text-decoration: none;
				&:hover {

				}
			}
		}

		> .kiemelt-projektek {
			padding-bottom: 55px;
			> h2 {
				margin-bottom: 25px;
			}

			.row {
				margin: 0;
				> div {
					padding-left: 0;
					> a {
						display: block;
						padding: 4px;
						border: 1px solid #e9e9e9;
						> img {
							width: 100%;
						}

						&:hover {
							border: 1px solid @bordo;
						}
					}
				}
			}
		}
	}

	> .fooldal-media {
		svg{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			max-width: 100px;

			circle, path ,g > circle, g > path{
				fill: #fff;
			}

			polygon, .st0{
				fill: @bordo !important;
				display: block;
			}
		}
		background-image: url("@{images-url}/media-hatter.jpg");
		height: auto;
		position: relative;
		> .container {
			position: relative;
			> h2 {
				> span {
					color: #fff;
					border-bottom: 3px solid #fff;
					font-size: 20px;
					font-weight: bold;
				}
			}
			.lefthover {
				position: absolute;
				top: 40%;
				left: -50px;
				cursor: pointer;
				&.disabled {
					display: none;
				}
			}
			.righthover {
				position: absolute;
				top: 40%;
				right: -50px;
				cursor: pointer;
				&.disabled {
					display: none;
				}
			}

			#frame {
				margin-top: 35px;
				margin-bottom: 30px;
				width: 100%;
				height: auto;
				padding: 0;

				.slidee {
					margin: 0;
					padding: 0;
					height: 100%;
					list-style: none;
					li {
						width: 375px;
						display: inline;
						float: left;
						margin: 0 5px 0 0;
						padding: 0;
						@media (max-width: @screen-md-max) {
							width: 310px;
						}
						height: 100%;
						> a {
							text-decoration: none;
							position: relative;

							.kep {
								overflow: hidden;
								position: relative;
								margin-bottom: 0;

								.youtube-div {
									background-position: center;
									background-size: cover;
									position: absolute;
									top: 4px;
									left: 4px;
									bottom: 4px;
									right: 4px;
								}
								.lejatszo {
									position: absolute;
									top: 0;
									left: 0;
									cursor: pointer;
								}
							}

							> p {
								padding-top: 15px;
								font-size: 18px;
								color: #fff;
								text-shadow: none;
								font-weight: 300;
								line-height: 1.2;
							}

							&:hover {
								.kep {
									background-color: @bordo;
									border-color: @bordo;
								}
							}
						}
					}
				}
			}

			.video-doboz-mobil {
				position: relative;
				text-decoration: none;
				.kep {
					overflow: hidden;
					position: relative;
					margin-bottom: 0;

					.youtube-div {
						background-position: center;
						background-size: cover;
						position: absolute;
						top: 4px;
						left: 4px;
						bottom: 4px;
						right: 4px;
					}
					.lejatszo {
						position: absolute;
						top: 0;
						left: 0;
						cursor: pointer;
					}

				}
				p {
					padding-top: 15px;
					font-size: 18px;
					color: #fff;
					text-shadow: none;
					font-weight: 300;
					line-height: 1.2;
				}
				&:hover {
					.kep {
						background-color: @bordo;
						border-color: @bordo;
					}
				}
			}
			.btn-feher {
				background: #fff;
				color: @bordo;
				border-radius: 0;
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 25px;
				&:hover{
					background-color: @szurke;
				}
			}
		}
	}
}