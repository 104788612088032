#munkatarsak {
	.munkatars-lista {
		margin-bottom: 45px;
		>div{
			margin-bottom: 15px;
		}
		.nev {
			color: #444;
			font-size: 20px;
			font-weight: bold;
		}
		.email {
			> a {
				color: #999;
				font-weight: bold;
				font-size: 14px;
				&:hover {
					color: @bordo;
					text-decoration: none;
				}
			}
		}
		.szoveg {
			color: #444;
			font-size: 14px;
		}
	}
}