header {
	background-image: url("@{images-url}/header.jpg");
	background-size: contain;
	height: auto;
	> .container {
		padding: 0;
		> .felso-resz {
			position: relative;
			 .brand-image {
				/*margin-top: 35px;
				margin-bottom: 20px;
				display: inline-block;*/
				max-width: 80%;
			}
			> .nyelvvalaszto {
				display: inline-block;
				position: absolute;
				bottom: 0;
				right: 0;
				background: #353134;
				margin: 0;
				padding: 9px 17px;
				> li {
					display: inline;
					color: #999;
					font-size: 20px;
					> a {
						color: #999;
						text-decoration: none;
						&:hover {
							color: #fff;
						}
					}
				}
			}
		}
		> .navbar-default {
			margin: 0;
			background-color: #fff;
			border: 0;
			border-radius: 0;
			> .container-fluid {
				padding-left: 0;
				> .navbar-collapse {
					@media (max-width: @screen-sm-max) {
						padding-left: 20px;
					}
					padding-left: 0;
					> .navbar-nav {
						display: block;
						> li {
							> a {
								padding: 20px 0;
								border-top: 3px solid transparent;
								font-size: 19px;
								@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
									font-size: 18px;
								}
								color: #000;
								> span {
									padding: 0 15px;
									border-left: 1px solid #000;
									@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
										padding: 0 7px;
									}
								}
								&:hover {
									> span {
										color: @bordo;
									}
								}
							}
							> ul {
								background: @szurke;
								border: 0;
								> li {
									> a {
										font-size: 19px;
										&:hover {
											background: #fff;
										}
									}
								}
							}
						}
						> li:first-child {
							> a > span {
								border-left: 0;
								padding-left: 15px;
							}
						}
						> .active {
							> a {
								background-color: #fff;
								border-top: 3px solid @bordo;
								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 50%;
									margin-left: -6px;
									width: 12px;
									height: 12px;
									border: 6px solid transparent;
									border-top: 6px solid @bordo;
								}
							}
						}
					}
					.navbar-right {
						padding-top: 15px;
						.kereses-gomb{
							float: right;
						}
					}
				}
			}
		}
	}
}
