#hirek {
	.hirek-lista {
		line-height: 1.1;
		> .media {
			margin-bottom: 40px;
			> .media-left {
				> .thumbnail {
					box-shadow: 0 0 10px #999;
					img {
						max-width: none;
					}
				}
			}
			.media-body {
				> .media-heading {
					color: #999;
					.datum {
						margin-bottom: 5px;
					}
					h3 {
						margin-top: 10px;
						font-size: 20px;
						font-weight: bold;
						color: #444;
					}
					hr {
						margin: 5px 0;
					}
					a {
						text-decoration: none;
						color: #444;
						&:hover {
							color: @bordo;
						}
					}
				}
				> .lead {
					font-size: 16px;
					margin-bottom: 15px;
					color: #444;
					line-height: 1.2;
				}
				.hirek-gomb {
					font-weight: bold;
				}
			}
		}
	}
}

#hir {

	> .container {
		margin-top: -10px;
		padding-bottom: 50px;
		.heading {
			color: #999;
			h3 {
				margin-top: 10px;
				font-size: 20px;
				font-weight: bold;
				color: #333;
			}
			hr {
				margin: 5px 0;
			}
			a {
				text-decoration: none;
				color: #999;
				&:hover {
					color: @bordo;
				}
			}
		}
		.hir-body {
			.cim {
				color: @bordo;
				font-size: 30px;
				font-weight: bold;
			}
			.lead {
				font-size: 18px;
				color: #000;
				font-weight: 600;
			}
			.szoveg {
				margin-top: 20px;
				font-size: 16px;
				color: #444;
				margin-bottom: 30px;
				overflow: hidden;
				a {
					color: @bordo;
					font-weight: bold;
					font-style: italic;
				}
			}
		}
	}
}