#fooldal {

	> #fooldal-slider {
		> .carousel-inner {
			> .item {
				> img {
					max-width: none;
					height: 100%;
				}
				> .carousel-caption {
					top: 20%;
					> .tartalom {
						> h3 {
							font-size: 20px;
						}
						> p {
							font-size: 14px;
							font-style: italic;
							color: #000;
							border-left: 10px solid #fff;
						}

						> .btn {
							padding: 14px 25px 14px;
							font-size: 16px;
						}
					}
				}
			}
		}

		> .carousel-control {
			> .fooldal-lapozo {
				position: absolute;
				top: 50%;
				display: inline-block;
			}
			&.left {
				//right: 10%;
				background-image: none;
			}
			&.right {
				right: 10%;
				background-image: none;
			}
		}
	}
	> .container {

		> .tovabbi-hireink, > .kiemelt-kutatasi-temak, > .kiemelt-projektek {
			> h2 {
				font-size: 25px;
			}
		}

		> .tovabbi-hireink {
			> .row {
				.thumbnail {
					> .caption {
						a {

							.datum {
								margin-bottom: 5px;
								border-bottom: 1px solid #999;
								font-size: 16px;
							}

							h3 {
								font-size: 22px;
							}
							.hir-szoveg {
								font-size: 18px;
							}
						}
					}
				}
			}
		}

		> .kiemelt-kutatasi-temak {
			> h2 {
				margin-bottom: 40px;
			}

			img {
				padding: 5px;
				border: 1px solid #e9e9e9;
			}
			.thumbnail {
				width: 150px;
				height: 150px;
				margin-left: auto;
				margin-right: auto;
				position: relative;
				.ikon-hatter {
					position: absolute;
					top: 4px;
					left: 4px;
					bottom: 4px;
					right: 4px;
					background-color: #e9e9e9;
					svg {
						max-width: 140px;
						max-height: 140px;
						padding: 21px;
						.vertical-align > .vertical-align-middle;
						.st3, .st4 {
							fill: #706f6f;
						}
					}
				}
			}
			.tema {
				&:hover {
					.thumbnail {

						.ikon-hatter {

							background-color: @bordo;
							svg {
								.st3, .st4 {
									fill: #fff;
								}
							}
						}
					}
				}
			}

			h3 {
				font-size: 20px;
			}

			a {
				text-decoration: none;
				&:hover {

				}
			}
		}

		> .kiemelt-projektek {
			padding-bottom: 55px;
			> h2 {
				margin-bottom: 25px;
			}

			.row {
				margin: 0;
				> div {
					padding-left: 0;
					> a {
						display: block;
						padding: 4px;
						border: 1px solid #e9e9e9;
						> img {
							width: 100%;
						}

						&:hover {
							border: 1px solid @bordo;
						}
					}
				}
			}
		}
	}
}
