#szolgaltatsok {
	.szolgaltatas-lista {
		.media {
			margin-bottom: 40px;
			.media-left{
				a{
				}
				img{
				}
			}
			.media-body {
				.cim {
				}
				.lead {
				}
			}
		}
	}
}

#szolgaltatas {
}