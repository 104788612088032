#elemzesek {
	.oldal-cimlec {
		background-image: url("@{images-url}elemzesek-hatter.jpg");
	}

	.elemzes {
		margin-bottom: 2.5em;
		padding-left: @grid-gutter-width;

		.cimkek {
			border-bottom: 1px solid @gray-light;

			a {
				color: @gray-light;
			}
		}

		.cim {
			font-weight: bold;
			font-size: 20px;
			margin-top: .25em;
			position: relative;

			/*&:before {
				content: '';
				display: block;
				position: absolute;
				left: -15px;
				top: 5px;
				.square(10px);
				border-radius: 50%;
				background: @bordo;
			}*/

			&:hover {
				color: @bordo;
			}
		}

		.btn {
			.text-uppercase;
			font-weight: bold;
			font-size: 16px;
		}
	}
}