#hirek {
	.hirek-lista {
		> .media {
			.media-body {
				> .media-heading {
					h3 {
						font-size: 25px;
					}
					.datum{
						font-size: 15px;
					}
				}
				> .lead {
					font-size: 18px;
				}
			}
		}
	}
}

#hir {

	> .container {
		.heading {
				font-size: 16px;
		}
		.hir-body {
			.cim {
				font-size: 32px;
			}
			.lead{
				font-size: 22px;
			}
			.szoveg{
				font-size: 18px;
			}
		}
	}
}